// src/App.js

import React from 'react';
import ExchangeRates from './ExchangeRates';

const App = () => {
  return (
    <div>
      <ExchangeRates />
    </div>
  );
};

export default App;
