// src/ExchangeRates.js

import React, { useState, useEffect } from 'react';
import './ExchangeRates.css';
import axios from 'axios';

function currencyNameInMM(currency) {
	const names = {
		"EUR": "ယူရို",
		"GBP": "စတာလင်ပေါင်",
		"AUD": "ဩစလေးလျဒေါ်လာ",
		"CAD": "ကနေဒါဒေါ်လာ",
		"SGD": "စင်္ကာပူဒေါ်လာ",
		"HKD": "ဟောင်ကောင်ဒေါ်လာ",
		"JPY": "ဂျပန်ယန်း",
		"CNY": "တရုတ်ယွမ်",
		"INR": "အိန္ဒိယရူပီး",
		"THB": "ထိုင်းဘတ်",
		"AED": "ယူအေအီဒါဟမ်",
		"KRW": "ကိုရီးယားဝမ်",
		"MYR": "မလေးရှားရင်းဂစ်",
		"VND": "ဗီယက်နမ်ဒေါင်",
		"PHP": "ဖိလစ်ပိုင်ပီဆို",
		"MMK": "မြန်မာကျပ်",	
		"USD": "အမေရိကန်ဒေါ်လာ",
	}

	return names[currency] || '';
}


function getChangeClass(rate, last) {

	// check last is a number
	if (isNaN(last)) {
		return 'change';
	}

	if (last < rate) {
		return 'change up';
	} else if (rate < last) {
		return 'change down';
	} else {
		return 'change';
	}
}

function getChangeDiff(rate, last) {
	// check last is a number
	if (isNaN(last)) {
		return '';
	}

	const options = {
		style: 'decimal',
		signDisplay: 'always',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	};

	const change = rate - last
	const formattedNumber = change.toLocaleString('en-US', options);
	return formattedNumber;
}

function isFirstTableCurrency(currency) {
	const firstTableCurrencies = [
		"USD",
		"SGD",
		"THB",
		"CNY",
		"INR",
	]
	return firstTableCurrencies.includes(currency);
}

function isCurrencyHidden(currency) {
	const hiddenCurrencies = [
		"XAU"
	]
	return hiddenCurrencies.includes(currency);
}

const CurrencyRow = ({ currency, rate, last_rate }) => {
	return (
		<tr key={currency}>
			<td>
				<div className='currency_container'>
					<div className='first_column'>
						<img
							src={`/flags/${currency.toLowerCase()}.png`}
							alt={currency}
							style={{ width: '20px', height: 'auto' }}
						/>
					</div>
					<div className='second_column'>
						<span className='source_symbol'>{currency}</span>
						<span className='source_name'>{currencyNameInMM(currency)}</span>
					</div>
				</div>
			</td>
			<td>
				<div className='rate_container'>
					<span className='rate'>{rate.toFixed(2)}</span>
					<span className='symbol'>MMK</span>
				</div>
			</td>
			<td>
				<div className='change_container'>
					<span className={getChangeClass(rate, last_rate)}>{getChangeDiff(rate, last_rate)}</span>
				</div>
			</td>
		</tr>
	)
}

const ExchangeRates = () => {
	const [rates, setRates] = useState([]);
	const [lastRates, setLastRates] = useState([]);
	const [lastUpdated, setLastUpdated] = useState('');

	useEffect(() => {
		const fetchRates = async () => {

			try {
				// const url = 'http://localhost:8080/home.json'
				const url = 'https://xerates.ttkz.me/home.json'
				const response = await axios.get(url, { withCredentials: false });
				setRates(response.data.prices);
				setLastRates(response.data.last_24hours_prices);
				const datetime = new Date(response.data.last_updated)
				const formattedDatetime = datetime.toLocaleString('en-US', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
					timeZone: 'Asia/Yangon'
				});
				setLastUpdated(formattedDatetime);
			} catch (error) {
				console.error('Error fetching exchange rates:', error);
			}
		};

		fetchRates();
	}, []);

	return (
		<div className='content'>
			<h2><img src='/ttfx-logo.png' alt='ttfx logo' /> မြန်မာကျပ်ငွေပေါက်စျေး</h2>
			<h4 className='last_updated'>{lastUpdated} အရ ပေါက်စျေးဖြစ်သည်။</h4>

			<div className='tables_container'>
				<div className='first_column'>
					<table className='first_table'>
						<thead>
							<tr>
								<th>ငွေ</th>
								<th>စျေး</th>
								<th>၂၄ နာရီ +/-</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(rates)
							.filter(([currency, rate]) => !isCurrencyHidden(currency))
							.filter(([currency, rate]) => isFirstTableCurrency(currency))
							.map(([currency, rate]) => (
								<CurrencyRow currency={currency} rate={rate} last_rate={lastRates[currency]} />
							))}
						</tbody>
					</table>
					<div>
						<p className='scan_text'>Scan to Download</p>
						<img src='/onelink_qr.svg' width='150px' alt='QR' />
					</div>
				</div>
				<div className='second_column'>
					<table className='second_table'>
						<thead>
							<tr>
								<th>ငွေ</th>
								<th>စျေး</th>
								<th>၂၄ နာရီ +/-</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(rates)
							.filter(([currency, rate]) => !isCurrencyHidden(currency))
							.filter(([currency, rate]) => !isFirstTableCurrency(currency))
							.map(([currency, rate]) => (
								<CurrencyRow currency={currency} rate={rate} last_rate={lastRates[currency]} />
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div className='notice'>
				<p>အရောင်းအဝယ်မလုပ်ပါ</p>
				<p>ဖော်ပြပါစျေးများသည် ရည်ညွှန်းရန်အတွက်သာဖြစ်ပါသည်။ အရောင်းအဝယ်ဖြစ်သော စျေးများနှင့် အနည်းငယ်(1%-2%) ကွာခြားနိုင်သည်။</p>
			</div>
		</div>
	);
};

export default ExchangeRates;
